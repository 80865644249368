import React from 'react'
import {Container, Grid} from '@mui/material'
import { graphql } from 'gatsby'
import {Helmet} from "react-helmet";

const titleName = "BrainNN22 - Submission"

const Submission = ({ data }) => {
    return (
        <Grid direction='row'
              justifyContent="center"
              alignItems="flex-start"
              display="flex"
              container>
            <Helmet>
                <title>{titleName}</title>
            </Helmet>
            <Grid item sm={8} xs={12}>
                <Container component="article" dangerouslySetInnerHTML={{
                    __html: data.markdownRemark.html
                }}/>
            </Grid>
        </Grid>
    )
}

export default Submission
export const pageQuery = graphql`
    query GetStartedPageQuery {
        markdownRemark(fileAbsolutePath: {regex: "/posts/submissions.md$/"}) {
            html
        }
    }
`

